import React, { FC, useCallback, useEffect } from "react";
import { navigate } from "gatsby";
import { RouteComponentProps } from "@reach/router";

import { useDispatch, useSelector } from "react-redux";
import { login } from "@/actions/account.actions";

import { Form, Input } from "antd";
import { Rule } from "antd/es/form";

import css from "./style.modules.scss";

import AuthLayout, { defaultInputProps } from "@/components/AuthLayout";
import useFormValidation from "@/hooks/useFormValidation";
import { AppStateType } from "@/reducers";

const FIELDS_RULES: Record<string, Rule[]> = {
  email: [
    {
      required: true,
      message: "Пожалуйста, введите ваш email"
    },
    {
      type: "email",
      message: "Некорректный формат email"
    },
  ],
  password: [{ required: true, message: "Пожалуйста, введите пароль" }],
};

interface INavigationListItem {
  text: string;
  onClick: () => Promise<void>;
}

interface IFormValues {
  email: string;
  password: string;
}

interface ILoginFormProps extends RouteComponentProps { }

const LoginForm: FC<ILoginFormProps> = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm<IFormValues>();
  const values = Form.useWatch([], form);
  const { isDisabled } = useFormValidation(form);
  
  const { authtoken, error } = useSelector((state: AppStateType) => state.account);

  useEffect(() => {
    if (authtoken) {
      navigate("/platform/home/");
    }
  }, [authtoken]);

  const navigationList: INavigationListItem[] = [
    { text: "Не помню пароль", onClick: () => navigate("/restore") },
    { text: "Зарегистрироваться", onClick: () => navigate("/registration") },
  ];

  const handleSubmit = useCallback((): void => {
    const { email, password } = values;

    dispatch(login(email, password));
  }, [values]);

  return (
    <AuthLayout
      title="Вход"
      message="С возвращением! Введите ваши данные для авторизации."
      buttonText="Войти"
      onButtonClick={handleSubmit}
      buttonDisabled={isDisabled}
      actions={
        navigationList.map(({ text, onClick }, index) => (
          <div className="m-0 text-center text-n2" key={index}>
            <a onClick={onClick} className={`${css.link} m-0 p-0`}>{text}</a>
          </div>
        ))
      }
    >
      <Form
        form={form}
        name="loginForm"
        className="flex flex-col gap-3 m-0"
      >
        <Form.Item name="email" className="m-0 w-full" rules={FIELDS_RULES.email}>
          <Input {...defaultInputProps} placeholder="Email" autoComplete="email" />
        </Form.Item>

        <Form.Item name="password" className="m-0 w-full" rules={FIELDS_RULES.password}>
          <Input.Password
            {...defaultInputProps}
            type="password"
            placeholder="Пароль"
            autoComplete="current-password"
          />
        </Form.Item>
      </Form>
      {!!error && (
        <div className="text-center text-error text-n1">
          {Array.isArray(error)
            ? error.map((err, index) => {
              return <span key={index} className="block mb-2">{err.msg}</span>
            })
            : error
          }
        </div>
      )}
    </AuthLayout>
  );
};

export default LoginForm;
